<template>
    <div>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            <!-- Mask -->
            
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">

                <div class="col-lg-7 p-0 col-md-10">
                    <h3 class="mb-0 text-white">Používateľ</h3>
                    <h2 class="">{{user.first_name}} {{user.last_name}}</h2>

                </div>
            </div>
        </div>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">

                    <div class="card card-profile shadow">
                        <div class="row justify-content-center">
                            <div class="col-lg-3 order-lg-2">
                                <div class="card-profile-image">
                                    <a href="#">
                                        <img v-if="croppedImage" :src="croppedImage" alt="avatar"  class="rounded-circle"/>
                                        <img v-else-if="user.avatar" :src="this.send + '../../../' + user.avatar" alt="avatar" class="rounded-circle"/>
                                    </a>
                                    <a-upload
                                            name="upload"
                                            listType="picture-card"
                                            :fileList="fileList"
                                            :headers="head"
                                            class="avatar-uploader"
                                            :showUploadList="false"
                                            :beforeUpload="beforeUpload"
                                            @change="handleChange"
                                    >
                                        <img v-if="croppedImage" :src="croppedImage" alt="avatar" class="avatar"/>
                                        <div v-else>
                                            <a-icon :type="loading ? 'loading' : 'plus'"/>
                                        </div>
                                    </a-upload>
                                </div>
                            </div>
                        </div>
                          <div class="card-body pt-0 pt-md-4">

                            <div class="text-center">
                                <h3>
                                    {{user.first_name}} {{user.last_name}}<span class="font-weight-light"></span>
                                </h3>
                                <div class="h5 font-weight-300">
                                    <i class="ni location_pin mr-2"></i> <span v-for="role in user.type_id">{{role}}</span>
                                </div>
                                <hr class="my-4"/>
                                <a v-bind:href="'mailto:'+user.email">{{user.email}}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-8 order-xl-1">
                    <a-card shadow type="secondary">
                          <template>
                            <form @submit.prevent="handleSubmit">
                                <h6 class="heading-small text-muted mb-4">Hlavné informácie</h6>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <a-input alternative=""
                                                        label="Meno"
                                                        input-classes="form-control-alternative"
                                                        v-model="user.first_name"
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <a-input alternative=""
                                                        label="Priezvisko"
                                                        input-classes="form-control-alternative"
                                                        v-model="user.last_name"
                                            />
                                        </div>

                                    </div> <div class="row">
                                    <div class="col-lg-6">
                                        <a-input alternative=""
                                                    label="Používateľské meno"
                                                    input-classes="form-control-alternative"
                                                    v-model="user.username"
                                        />
                                    </div>
                                    <div class="col-lg-6">
                                        <a-input alternative=""
                                                    label="Email"
                                                    input-classes="form-control-alternative"
                                                    v-model="user.email"
                                        />
                                    </div>
                                </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <a-input
                                                    label="Heslo"
                                                    type="password"
                                                    input-classes="form-control-alternative"
                                                    v-model="user.password"/>
                                        </div>
                                        <div class="col-lg-6">
                                            <a-input alternative=""
                                                        label="Zopakujte heslo"
                                                        type="password"
                                                        input-classes="form-control-alternative"
                                                        v-model="user.password_repeat"/>
                                        </div>

                                    </div>


                                </div>
                                <a-modal title="Upraviť obrázok" v-model="visible" @ok="handleOk">
                                    <cropper v-if="imageUrl"
                                             classname="cropper"
                                             :src="this.imageUrl"
                                             :stencilProps="{aspectRatio: 12/12}"
                                             @change="change"
                                    ></cropper>
                                </a-modal>



                                <hr class="my-4"/>

                                <div class="text-right">
                                    <button class="btn btn-primary">Uložiť</button>
                                </div>

                            </form>
                        </template>
                    </a-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {API_URL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import {Modal} from 'ant-design-vue'
    import FormItem from 'ant-design-vue'
    import {Cropper} from 'vue-advanced-cropper'

    Vue.use(FormItem, Modal);
    Vue.use(Cropper);

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }


    export default {
        name: 'user',

        data() {
            return {
                loading: false,
                visible: false,
                originInfo: '',
                id: JSON.parse(localStorage.user).data.id,
                imageUrl: '',
                fileList: [],
                roleOptions: [],
                imgCroppFile: '',
                uploading: false,
                croppedImage: '',
                imageUploaded: '',
                colorsto: JSON.parse(localStorage.colors),
                roles: '',
                head: authHeader(),
                send: API_URL,
                formItemLayout: {
                    labelCol: {span: 6},
                    wrapperCol: {span: 14},
                },
                userId: {
                    uid: this.id
                },
                user: {
                    first_name: '',
                    last_name: '',
                    image: this.imageUploaded,
                },
                model: {
                    username: '',
                    email: '',
                    firstName: '',
                    lastName: '',
                    address: '',
                    city: '',
                    country: '',
                    zipCode: '',
                    about: '',
                }
            }
        },
        methods: {
            showModal() {
                this.visible = true;
            },
            handleOk() {
                this.visible = false;
                var headers = authHeader(true);
                let formData = new FormData();
                formData.append('upload', dataURLtoFile(this.imgCroppFile, this.originInfo.name));
                const pa = this;
                this.axios.post(API_URL + '/upload/user',
                    formData,
                    {
                        headers: headers
                    }
                ).then(function (data) {
                    pa.user.imageUploaded = data.data[0];
                    console.log(pa);
                });

                return true;
            },
            change({coordinates, canvas}) {
                this.croppedImage = canvas.toDataURL();
                this.imgCroppFile = canvas.toDataURL("image/jpeg");

            },
            handleChange(info) {

            },
            beforeUpload(file) {
                this.originInfo = file;
                const isJPG = file.type === 'image/jpeg';
                if (!isJPG) {
                    this.$message.error('You can only upload JPG file!');
                }
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    this.$message.error('Image must smaller than 2MB!');

                }
                getBase64(file, imageUrl => {
                    this.imageUrl = imageUrl;
                    this.showModal();
                });
                return false;
            },
            sayshit(e) {
                console.log(e);
            },
            handleSubmit() {
                let $user_data = this.user;

                console.log($user_data);
                dataService.axiosPost(this.$options.name, $user_data);
            },
            normFile(e) {
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;


            },
        },
        mounted() {
            dataService.axiosFetch("roles").then(results => { this.roles = results;
                var roleops = [];

                results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });

                this.roleOptions = roleops;
            });
            if (!isNaN(this.id)) {
                dataService.axiosFetch(this.$options.name, this.id).then(results => {
                    this.user = results;
                 //   this.user.type_id = JSON.parse(this.user.type_id);
                });
            }
        }
    };
</script>
<style>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }
    .ant-upload.ant-upload-select-picture-card
    {
        background-color: transparent;
        border:0;}
    .avatar-uploader
    {z-index: 9999;
        position: relative;
        display: block;
        border-radius: 100%;
        overflow: hidden;
        top: -12px;}
    .ant-upload.ant-upload-select-picture-card > .ant-upload
    {color:#fff;}

</style>
